import React from "react"
import { Helmet } from "react-helmet"

import BaseLayout from "../layout/BaseLayout"

const NotFoundPage = () => (
  <BaseLayout classTheme="theme-1">
    <Helmet>
      <title>404 Not found - Cocidoware</title>
      <meta name="description" content="Estudio de desarrollo de videojuegos" />
      <meta property="og:title" content="404 Not found - Cocidoware" />
      <meta property="og:url" content="https://cocidoware.com/" />
      <meta
        name="og:description"
        content="Estudio de desarrollo de videojuegos"
      />
    </Helmet>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </BaseLayout>
)

export default NotFoundPage
